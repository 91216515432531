import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Flex, DesktopContainer, MobileContainer } from '../components/ui/Layout'
import Button from '../components/ui/Button'
import Spinner from '../components/ui/Spinner'
import Image from '../components/ui/Image'
import { colors, smallBoxShadow } from '../components/ui/helpers.js'
import { urlToString } from '../components/ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { H1, H2, H3, SeoH2, SeoH4, Span, Text, LinkSpan } from '../components/ui/Typography'
import OnlineIcon from '../components/ui/OnlineIcon'
import { translate, countryTranslate, activeCountries } from '../../constants/translations'

import Layout from '../components/layout'
import SideMenu from '../components/SideMenu'
import DesktopSideBar from '../components/DesktopSideBar'
import Helmet from '../components/Helmet'
import Footer from '../components/Footer'

const FullHeightContainer = styled(Flex)`
  min-height: 100vh;
`

const MainContentContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding: 100px;
  padding-top: 0px;
  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 700px) {
    border-radius: 32px 32px 0px 0px;
    padding-top: 24px;
    margin-top: -48px;
    background-color: ${colors.lighterGrey};
    position: relative;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.9);
  }
`

const Menu = styled(Flex)`
  height: 60px;
  background-color: ${colors.darkBlue};
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  position: sticky;
  top: 0;
  z-index: 1;
`

const HeroContentContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding: 100px;
  padding-bottom: 0px;
  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 700px) {
    padding-top: 60px;
    background-color: ${colors.darkBlue};
    h1 {
      font-size: 28px;
      color: white !important;
    }
    h2 {
      color: white !important;
    }
    h3 {
      color: white !important;
    }
    p {
      color: white !important;
      font-size: 14px;
      line-height: 18px;
    }
    padding-bottom: 60px;
  }
`

const FlagImage = styled.img`
  border-radius: 8px;
  margin-right: 16px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
`

const Flag = ({ iso2 }) => {
  return <FlagImage alt={`${iso2} flag`} src={`/country-flag/${iso2}.svg`} width="30px" height="30px" />
}

const LoadingMessage = styled(Flex)`
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  background-color: white;
  padding: 16px;
  height: 80px;
  border-radius: 16px;
  margin-bottom: 16px;
  background-color: white;
  @media (max-width: 700px) {
    flex-direction: column;
    background-color: ${colors.lightGrey};
    background-image: linear-gradient(100deg, ${colors.lightGrey} 0%, #f7f9ff 10%, #f9fbfc 50%, ${colors.lightGrey} 70%);
  }
  position: relative;
  background-image: linear-gradient(100deg, white 0%, #f7f9ff 10%, #f9fbfc 50%, white 70%);
  background-size: 400%;
  animation: loading 0.8s infinite;
  z-index: 1;
`

const Message = styled(Flex)`
  background: white;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
  background-color: white;
  &:hover {
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 700px) {
    flex-direction: column;
    background-color: ${colors.lightGrey};
  }
`
const MessagesContainer = styled(Flex)`
  width: auto;
  max-width: 800px;
  flex-direction: column;
`

const MessageText = styled(Text)`
  word-break: break-word;
`

const SenderText = styled(Text)`
  @media (max-width: 600px) {
    margin-top: 10px;
    font-size: 14px;
    line-height: 14px;
  }
`

const DesktopButtonContainer = styled(Flex)`
  @media (max-width: 600px) {
    display: none;
  }
`

const MobileButtonContainer = styled(Flex)`
  display: none;
  @media (max-width: 600px) {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  }
`

const BreadCrumbContainer = styled(Flex)`
  padding-left: 100px;
  p {
    font-weight: 300;
  }
  @media (max-width: 1200px) {
    padding-left: 22px;
  }
  @media (max-width: 500px) {
    padding-top: 16px;
    p {
      font-size: 14px;
      color: white;
    }
    span {
      color: white;
      margin-left: 10px;
      margin-right: -6px;
    }
  }
`

const HomeImage = styled(Image)`
  @media (max-width: 500px) {
    display: none;
  }
`

const Li = styled.li`
  display: inline-block;
`

const Ul = styled.ul`
  display: flex;
  align-items: center;
`

const AboutPage = ({}) => {
  return (
    <Layout>
      <Helmet
        title={'About Receive SMS online | 18+ Countries | 100% Free'}
        lang="en"
        description={
          'Receive SMS online - The largest collection of temporary phone numbers on the internet. 15+ Countries, 100+ numbers. 100% free, no registration.'
        }
      >
        <html lang={'en'} />
      </Helmet>
      <FullHeightContainer flexDirection="column" alignItems="center">
        <Menu>
          <Link to={`/`}>
            <Flex width="auto" alignItems="flex-end">
              <Image alt="home icon" mr="14px" width="30px" height="30px" src="/home.svg" />
              <Text bold color="white">
                Home
              </Text>
            </Flex>
          </Link>
          <Text color="white">
            <Span color="white" fontSize="26px" fontWeight="bold" mr="20px">
              Receiveasmsonline
            </Span>{' '}
            © {new Date().getFullYear()}
          </Text>
        </Menu>
        <Flex maxWidth="900px" pt="60px" flexDirection="column">
          <H1 mb="20px">About</H1>
          <Text mb="40px">
            Receiveasmsonline is the largest collection of temporary phone numbers on the internet. We provide hundres of temporary phone numbers from
            over 18 countries for our users to use for free. We don't require any registration, don't show any ads and are 100% free.
          </Text>
          <Text mb="40px">
            You can use our numbers for any purpose such as, temporarily verifying an online account, using international services or signing up to a
            marketing list. All messages we receive we will show to you!
          </Text>
          <H1 mb="20px">Contact us</H1>
          <Text mb="20px">If you'd like us to add more numbers, support another country or have any feedback do contact us on the email below:</Text>
          <Text fontSize="18px" bold ml="40px" mb="50px">
            receiveasmsonline@gmail.com
          </Text>
        </Flex>
        <div
          dangerouslySetInnerHTML={{
            __html: '<script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>'
          }}
        />
        <div
          class="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="5419b6a8b0d04a076446a9ad"
          data-businessunit-id="6344015388ac456b9d9f6091"
          data-style-height="24px"
          data-style-width="100%"
          data-theme="light"
          data-min-review-count="10"
          data-style-alignment="center"
        >
          <a href="https://uk.trustpilot.com/review/receiveasmsonline.com" target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
      </FullHeightContainer>
      <Footer iso2={'en'} />
    </Layout>
  )
}
export default AboutPage

//http://localhost:8000/united-kingdom/447901614024
// d1uyh0a3eznojw.cloudfront.net/united-kingdom/447901614024
